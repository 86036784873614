<template>
  <div>
    <div class="header_fix">
      <div class="header flex-box">
        <router-link to="/" class="icon_logo">
          <img src="../assets/static/icon/icon_logo.png" class="icon_logo" alt="">
        </router-link>
        <a href="javascript:;" class="fs18 col9">
          <span>已有账号？</span>
          <span class="normal"><router-link to="/login">请登录</router-link>></span>
        </a>
      </div>
    </div>
    <div class="content">
      <div class="login">
        <div class="login_box">
          <div class="fs22 pb20">欢迎注册</div>
          <div class="input_box flex-box fs18 account">
            <input type="text" v-model="user.tel" class="flex-grow-1" placeholder="请输入手机号" />
            <div class="code normal" v-if="show" @click="sendSms">发送验证码</div>
            <div v-else class="code normal count">{{ count }}s</div>
          </div>
          <div class="input_box flex-box fs18">
            <input type="text" v-model="user.smsCode" class="flex-grow-1" placeholder="请输入验证码" />
          </div>
          <div class="input_box flex-box fs18">
            <input type="password" v-model="user.password" class="flex-grow-1" placeholder="请输入密码" />
          </div>
          <div class="register fs18" v-if="is_reg == false" @click="register">注册</div>
          <div class="register fs18 active" v-else>登录中...</div>
        </div>
      </div>
    </div>
    <div class="m1440 tr pt30 fs18">环保桥（上海）环境技术有限公司</div>
  </div>
</template>
<script>
import { setTimeout } from 'timers';
import cookie from "vue-cookies";
import axios from "axios";
export default {
  data() {
    return {
      user: {
        tel: '',
        smsCode: '',
        password: ''
      },
      show: true,
      count: 60,
      is_reg: false,//防止多次点击
    }
  },
  methods: {

    sendSms: function () {
      let that = this;
      let data = that.user;
      if (data.tel != '') {
        if (!(/^1[3456789]\d{9}$/.test(data.tel))) {
          that.$message.error("请填写正确的手机号码！");
          return;
        }
      } else {
        that.$message.error("请输入手机号！");
        return;
      }

      that.$api.apiPostSend({ tel: data.tel })
        .then((res) => {
          // console.log(data_res);
          if (res.resCode == 0) {
            const TIME_COUNT = 60;
            if (!that.timer) {
              that.count = TIME_COUNT;
              that.show = false;
              that.timer = setInterval(() => {
                if (that.count > 0 && that.count <= TIME_COUNT) {
                  that.count--;
                } else {
                  that.show = true;
                  clearInterval(that.timer);
                  that.timer = null;
                }
              }, 1000);
            }
            // that.user.code = res.root
          } else {
            that.$message.error(res.resMsg);
          }
        });
    },

    //注册
    register() {
      let that = this;
      let data = that.user;
      if (data.tel != '') {
        if (!(/^1[3456789]\d{9}$/.test(data.tel))) {
          that.$message.error("请填写正确的手机号码！");
          return;
        }
      } else {
        that.$message.error("请输入手机号！");
        return;
      }
      if (data.smsCode == '') {
        that.$message.error("请输入验证码！");
        return;
      }
      if (data.password == '') {
        that.$message.error("请输入密码！");
        return;
      }
      if (data.password.length < 8 || data.password.length > 30) {
        that.$message.error("请输入8-30位的密码！");
        return;
      }
      var regex = new RegExp('^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,30}$');
      if (!regex.test(data.password)) {
        that.$message.error("密码可使用数字、大写字母、小写字母及特殊字符，至少包含三种字符");
        return;
      }
      data.loginType = '1'
      that.is_reg = true
      that.$api.apiPostRegister(data).then((res) => {
        if (res.resCode == 0) {
          that.is_reg = false
          that.$message.success('注册成功');
          cookie.set("huanbaoqiao_userinfo", res.root)
          // cookie.set("huanbaoqiao_token", res.root.token);
          // axios.defaults.headers["huanbaoqiao_token"] = res.root.token; // 登录标识
          setTimeout(function () {
            that.$router.push("/login");
            // that.$router.back()
          }, 800)

        } else {
          that.is_reg = false
          that.$message.error(res.resMsg);
        }
      });
    }
  }
}

</script>



